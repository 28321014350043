@charset "UTF-8";
@import "../common/_settings/_setting";

/**
 * index
 */
body[id="error"] {
  .pageTitle {
    @include mq-pc {
      margin-bottom: 14.3rem;
    }
    @include mq-sp {
      margin-bottom: (71.25 / 375 * 100) + vw;
    }
  }
  .-formBtn {
    @include mq-pc {
      margin-top: 5.8rem;
    }
    @include mq-sp {
      margin-top: (33 / 375 * 100) + vw;
    }
  } //-formBtn
}
